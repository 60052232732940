<template>
    <v-dialog v-model="show" persistent max-width="400" scrollable>
        <v-card>
            <v-card-title>Add Contact</v-card-title>
            <v-card-text class="mt-3">
                <v-text-field
                    v-model="form.name"
                    :error-messages="form.errors.get('name')"
                    @input="form.errors.clear('name')"
                    label="Name (Required)"
                    autofocus
                />
                <v-select
                    v-model="form.customer_sites"
                    :items="sites"
                    item-text="title"
                    item-value="id"
                    label="Sites (Required)"
                    :error-messages="form.errors.get('customer_sites')"
                    @input="form.errors.clear('customer_sites')"
                    multiple
                    chips
                    deletable-chips
                />
                <v-select
                    v-model="form.roles"
                    :items="roles"
                    item-text="title"
                    item-value="name"
                    label="Roles (Required)"
                    :error-messages="form.errors.get('roles')"
                    @input="form.errors.clear('roles')"
                    multiple
                    chips
                    deletable-chips
                />
                <v-text-field
                    v-model="form.email"
                    :error-messages="form.errors.get('email')"
                    @input="form.errors.clear('email')"
                    label="Email address (Required)"
                />
                <v-select
                    v-model="form.status"
                    :items="statuses"
                    item-text="title"
                    item-value="id"
                    label="Status (Required)"
                    :error-messages="form.errors.get('status')"
                    @input="form.errors.clear('status')"
                />
                <v-text-field
                    v-model="form.mobile_phone"
                    :error-messages="form.errors.get('mobile_phone')"
                    @input="form.errors.clear('mobile_phone')"
                    label="Mobile phone"
                />
                <v-text-field
                    v-model="form.home_phone"
                    :error-messages="form.errors.get('home_phone')"
                    @input="form.errors.clear('home_phone')"
                    label="Home phone"
                />
                <div class="d-flex">
                    <v-text-field
                        v-model="form.work_phone"
                        :error-messages="form.errors.get('work_phone')"
                        @input="form.errors.clear('work_phone')"
                        label="Work phone"
                        class="flex-1 mr-4"
                    />
                    <v-text-field
                        v-model="form.work_phone_extension"
                        :error-messages="form.errors.get('work_phone_extension')"
                        @input="form.errors.clear('work_phone_extension')"
                        label="Extension"
                    />
                </div>
                <v-text-field
                    v-model="form.fax"
                    :error-messages="form.errors.get('fax')"
                    @input="form.errors.clear('fax')"
                    label="Fax"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text :disabled="saving" @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="saving" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from '@/utils/form';
import Http from '@/utils/httpClient';

export default {
    data() {
        return {
            saving: false,
            show: false,
            roles: [],
            sites: [],
            statuses: [],
            form: new Form({
                name: null,
                customer_sites: null,
                roles: null,
                email: null,
                status: null,
                mobile_phone: null,
                home_phone: null,
                work_phone: null,
                work_phone_extension: null,
                fax: null
            })
        };
    },
    watch: {
        $route() {
            this.init();
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (this.$route.name === 'customer.contacts.create') {
                this.open();
            }
        },
        open() {
            this.$root.$loading.open();
            Http.get('contacts/create')
                .then(({ data }) => {
                    this.$root.$loading.close();
                    this.roles = data.roles;
                    this.statuses = data.statuses;
                    this.sites = data.sites;
                    this.form.reset();
                    this.show = true;
                })
                .catch(() => this.$root.$loading.close());
        },
        close() {
            this.show = false;
            this.$router.push({
                name: this.$route.meta.parent
            });
        },
        save() {
            this.saving = true;
            Http.post('contacts', this.form.payload())
                .then(({ data }) => {
                    this.saving = false;
                    this.close();
                    this.$root.$snackbar.open({
                        text: `Customer contact ${this.form.name} was successfully added.`,
                        type: 'success'
                    });
                    this.$emit('success', data);
                })
                .catch(({ response }) => {
                    this.saving = false;
                    this.form.errors.record(response.data);
                });
        }
    }
};
</script>
